import React, { Component, Fragment } from 'react';
import './App.css';
import albumart from '../albumart.jpg';
import AudioSpectrum from 'react-audio-spectrum';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {
  CloudUpload,
  MusicNoteList,
  PlusCircleFill,
  Gear,
} from 'react-bootstrap-icons';
import axios from 'axios';
import AudioPlayer from 'react-h5-audio-player';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      maticHKD: -1,
      costHkd: '',
      newPlaylistModalShow: false,
      addToPlaylistModalShow: false,
      setupModalShow: false,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.createPlaylistModal = this.createPlaylistModal.bind(this);
    this.addToPlaylistModal = this.addToPlaylistModal.bind(this);
    this.setupModal = this.setupModal.bind(this);
    this.updateCostHkd = this.updateCostHkd.bind(this);
    this.fetchMaticHKD = this.fetchMaticHKD.bind(this);
    this.addSongToPlaylist = this.addSongToPlaylist.bind(this);
    this.player = React.createRef();
  }

  componentDidMount() {
    this.fetchMaticHKD();
  }

  handleSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  createPlaylistModal = () => {
    this.setState({
      newPlaylistModalShow: !this.state.newPlaylistModalShow,
    });
  };

  addToPlaylistModal = () => {
    this.setState({
      addToPlaylistModalShow: !this.state.addToPlaylistModalShow,
    });
  };

  setupModal = () => {
    this.setState({
      setupModalShow: !this.state.setupModalShow,
    });
  };

  updateCostHkd = (event) => {
    this.setState({ costHkd: (event.target.value * this.state.maticHKD).toFixed(4) });
  };

  addSongToPlaylist = (playlistId, videoId) => {
    // close modal
    this.setState({
      addToPlaylistModalShow: !this.state.addToPlaylistModalShow,
    });
    // add song to playlist
    this.props.addToPlaylist(playlistId, videoId);
  };

  async fetchMaticHKD() {
    // fetch MATIC - HKD conversion rate
    var price = this.state.maticHKD;
    await axios
      .get('https://api.coingecko.com/api/v3/simple/price', {
        params: {
          ids: 'matic-network',
          vs_currencies: 'hkd',
        },
      })
      .then(function (response) {
        price = response.data['matic-network']['hkd'];
      })
      .catch((err) => {
        console.log('Matic HKD fetch error', err);
      });
    this.setState({
      maticHKD: price,
      costHkd: (0.002 * price).toFixed(4)
    });
  }

  render() {
    // fetch current playlist details
    var currentPlaylist = this.props.playlist;
    if (currentPlaylist.id != 'all') {
      currentPlaylist = this.props.playlists.find(
        (playlist) => playlist.id == this.props.playlist.id
      );
    }

    const newPlaylistButton = (
      <div>
        <Button variant='primary' onClick={this.createPlaylistModal}>
          <MusicNoteList /> Playlist
        </Button>
        <Modal
          show={this.state.newPlaylistModalShow}
          onHide={this.createPlaylistModal}
        >
          <Modal.Header closeButton>
            <h3>Create Playlist</h3>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const title = this.playlistTitle.value;
                this.setState({
                  newPlaylistModalShow: !this.state.newPlaylistModalShow,
                });
                this.props.createPlaylist(title);
              }}
            >
              <div className='form-group mr-sm-2'>
                <input
                  id='playlistTitle'
                  type='text'
                  ref={(input) => {
                    this.playlistTitle = input;
                  }}
                  className='form-control mt-3 mr-3'
                  placeholder='Title for playlist'
                  required
                />
              </div>
              <button
                type='submit'
                className='btn border btn-success btn-block mt-3'
              >
                Confirm
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );

    const uploadComponent = (
      <div>
        <Button variant='primary' onClick={this.props.handleUploadModal}>
          <CloudUpload /> Song
        </Button>
        <Modal
          show={this.props.uploadModalShow}
          onHide={this.props.handleUploadModal}
        >
          <Modal.Header closeButton>
            <h3>Song Upload</h3>
          </Modal.Header>
          <Modal.Body>
            {this.props.uploading && (
              <div className='row'>
                <div className='col-5'></div>
                <div className='col-2'>
                  <ScaleLoader
                    color='#2A6BB8'
                    loading='true'
                    height={35}
                    width={6}
                    radius={2}
                    margin={2}
                  />
                </div>
                <div className='col-5'></div>
              </div>
            )}
            {!this.props.uploading && (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const title = this.videoTitle.value;
                  const artistName = this.artistName.value;
                  const costPerStream = this.costPerStream.value;
                  if (costPerStream > 0.007) {
                    alert('We recommend a cost per stream < 0.007 MATIC');
                    return;
                  }
                  this.props.uploadVideo(title, artistName, costPerStream);
                }}
              >
                <div className='row mb-3'>
                  <div className='col-2'>
                    <label className='form-label'>Audio</label>
                  </div>
                  <div className='col-10'>
                    <Form.Group>
                      <input
                        className='form-control'
                        type='file'
                        accept='.mp4, .mov, .mp3, .mkv .ogg .wmv'
                        onChange={this.props.captureFile}
                        id='songFile'
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className='form-group mr-sm-2'>
                  <input
                    id='videoTitle'
                    type='text'
                    ref={(input) => {
                      this.videoTitle = input;
                    }}
                    className='form-control mt-3 mr-3'
                    placeholder='Title'
                    required
                  />
                </div>
                <div className='form-group mr-sm-2'>
                  <input
                    id='artistName'
                    type='text'
                    ref={(input) => {
                      this.artistName = input;
                    }}
                    className='form-control mt-3 mr-3'
                    placeholder='Artist'
                    required
                  />
                </div>
                <div className='form-group mr-sm-2'>
                  <input
                    id='costPerStream'
                    type='number'
                    step='any'
                    min='0'
                    max='0.06'
                    ref={(input) => {
                      this.costPerStream = input;
                    }}
                    className='form-control mt-3 mr-3 mb-3'
                    placeholder='Cost per stream in MATIC'
                    defaultValue='0.002'
                    required
                    onChange={this.updateCostHkd}
                  />
                  <label>~HK${this.state.costHkd}</label>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <label className='form-label'>Artwork</label>
                  </div>
                  <div className='col-10'>
                    <input
                      className='form-control'
                      type='file'
                      accept='image/png, image/jpeg'
                      onChange={this.props.captureArtwork}
                      id='artFile'
                    />
                  </div>
                </div>
                <button
                  type='submit'
                  className='btn border btn-success btn-block mt-3'
                >
                  Confirm
                </button>
              </form>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );

    const searchBarComponent = (
      <div className='form-group form-inline'>
        <input
          className='form-control'
          type='text'
          placeholder='&#xf002;  Search for a song/artist'
          aria-label='Search'
          onChange={this.handleSearch}
          value={this.state.search}
          style={{
            width: '100%',
            fontFamily: 'FontAwesome, Helvetica Neue, sans-serif',
          }}
        />
      </div>
    );

    const sorterComponent = (
      <Form.Control
        as='select'
        value={this.props.sort}
        onChange={(e) => this.props.sorter(e.target.value)}
        style={{ cursor: 'pointer' }}
      >
        <option value='id'>Release Date</option>
        <option value='artist'>Artist</option>
        <option value='title'>Title</option>
      </Form.Control>
    );

    const playlistsComponent = (
      <Form.Control
        as='select'
        value={this.props.playlist.id}
        onChange={(e) =>
          this.props.selectPlaylist(
            e.target.value,
            e.target.selectedOptions[0].text
          )
        }
        style={{ cursor: 'pointer' }}
      >
        <option value='all'>All Songs</option>
        {this.props.playlists.map((playlist, key) => {
          return (
            <option key={'option' + key} value={playlist.id}>
              {playlist.title}
            </option>
          );
        })}
      </Form.Control>
    );

    const addToPlaylistComponent = (
      <Fragment>
        <div className='col-2'>
          <div
            className='card mb-4 ml-4 mr-4 mt-4 text-center hover-overlay bg-secondary mx-auto'
            style={{ width: '195px' }}
          >
            <div className='card-title bg-dark'>
              <small className='text-white'>
                <b style={{ color: 'white' }}>Add Song</b>
              </small>
            </div>
            <div className='image'>
              <p onClick={this.addToPlaylistModal}>
                <img
                  src={albumart}
                  alt='Album Art'
                  style={{ width: '160px', height: '160px' }}
                  className='artwork add-artwork'
                />

                <div className='artwork-overlay'>
                  <div className='artwork-title'>
                    <PlusCircleFill size='2x' />
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.addToPlaylistModalShow}
          onHide={this.addToPlaylistModal}
        >
          <Modal.Header closeButton>
            <h3>Add to Playlist</h3>
          </Modal.Header>
          <Modal.Body>
            <table className='table table-hover'>
              <thead>
                <th scope='col'>#</th>
                <th scope='col'>Title</th>
                <th scope='col'>Artist</th>
              </thead>
              <tbody>
                {this.props.videos
                  .filter(
                    (video) =>
                      currentPlaylist.id == 'all' ||
                      !currentPlaylist.songs.includes(video.id.toString())
                  )
                  .map((video, index, key) => {
                    return (
                      <tr key={'table' + key}>
                        <th className='song-not-in-playlist'>{index + 1}</th>
                        <th
                          onClick={() =>
                            this.addSongToPlaylist(currentPlaylist.id, video.id)
                          }
                          className='song-not-in-playlist playlist-song-name'
                        >
                          {video.title}
                        </th>
                        <th className='song-not-in-playlist'>{video.artist}</th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </Fragment>
    );

    const songCardsComponent = (
      <div className='row border border-secondary'>
        <br />
        {this.props.videos
          .filter(
            (video) =>
              currentPlaylist.id == 'all' ||
              currentPlaylist.songs.includes(video.id.toString())
          )
          .filter(
            (video) =>
              this.state.search === '' ||
              video.title
                .toLowerCase()
                .includes(this.state.search.toLowerCase()) ||
              video.artist
                .toLowerCase()
                .includes(this.state.search.toLowerCase())
          )
          .map((video, key) => {
            return (
              <div className='col-2' key={'video' + key}>
                <div
                  className='card mb-4 ml-4 mr-4 mt-4 text-center hover-overlay bg-secondary mx-auto'
                  style={{ width: '195px' }}
                >
                  <div className='card-title bg-dark'>
                    <small className='text-white'>
                      <b style={{ color: 'white' }}>
                        {video.title} - {video.artist}
                      </b>
                    </small>
                  </div>
                  <div className='image'>
                    <p
                      onClick={() =>
                        this.props.changeVideo(
                          video.id,
                          video.hash,
                          video.title,
                          video.costPerStream,
                          video.artist
                        )
                      }
                    >
                      {video.artHash === 'null' && (
                        <img
                          src={albumart}
                          alt='Album Art'
                          style={{ width: '160px', height: '160px' }}
                          className='artwork'
                        />
                      )}
                      {video.artHash !== 'null' && (
                        <img
                          src={`https://ipfs.infura.io/ipfs/${video.artHash}`}
                          alt='Album Art'
                          style={{ width: '160px', height: '160px' }}
                          className='artwork'
                        />
                      )}
                      {/* Not currently playing */}
                      {video.id != this.props.currentId && (
                        <div className='artwork-overlay'>
                          <div className='artwork-title'>
                            {video.costPerStream} MATIC
                            <br />
                            ~HK$ {(video.costPerStream * this.state.maticHKD).toFixed(4)}
                          </div>
                        </div>
                      )}
                      {/* Currently playing */}
                      {video.id == this.props.currentId && (
                        <div className='now-playing-overlay'>
                          <AudioSpectrum
                            id='audio-visualizer'
                            height={161}
                            width={161}
                            audioEle={this.player.current.audio.current}
                            capColor={'white'}
                          />
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        {this.props.playlist.id != 'all' && addToPlaylistComponent}
      </div>
    );

    const audioPlayerComponent = (
      <div className='col-12 fixed-bottom'>
        <div>
          <AudioPlayer
            autoPlay
            src={this.props.source}
            header={`${this.props.currentTitle} - ${this.props.currentArtist}`}
            customAdditionalControls={[]}
            id='audioplayer'
            ref={this.player}
          />
        </div>
      </div>
    );

    const setupComponent = (
      <div className='col-12'>
        <Button variant='primary' onClick={this.setupModal}>
          <Gear /> Setup Guide
        </Button>
        <Modal
          show={this.state.setupModalShow}
          onHide={this.setupModal}
        >
          <Modal.Header closeButton>
            <h3>Setup Guide</h3>
          </Modal.Header>

          <Modal.Body>
            <ol>
              <li>Install Metamask browser extension from <a href='https://metamask.io/' target='_blank'>here</a></li>
              <li>Follow the login instructions to create a new Metamask wallet</li>
              <li>Open the Metamask extension and click on the circular icon on the top right</li>
              <li>Select Settings -> Networks -> Add Network</li>
              <li>Enter the following information:
                <ul>
                  <li>Network Name: Mumbai Testnet</li>
                  <li>New RPC URL: https://rpc-mumbai.maticvigil.com</li>
                  <li>Chain ID: 80001</li>
                  <li>Currency Symbol: MATIC</li>
                  <li>Block Explorer URL (Optional): https://mumbai.polygonscan.com/</li>
                  <li>Save</li>
                </ul>
              </li>
              <li>Reload BeatChain and confirm the Metamask connection request</li>
              <li>You can get MATIC tokens from <a href='https://faucet.polygon.technology/' target='_blank'>here</a> by pasting your Metamask account address</li>
              <li>Happy Streaming!</li>
            </ol>
          </Modal.Body>
        </Modal>
      </div>
    )

    const toolbar = (
      <div className='row'>
        <div className='col-3'>{playlistsComponent}</div>
        <div className='col-3'>{searchBarComponent}</div>
        <div className='col-2'>{sorterComponent}</div>
        <div className='col-1'>{uploadComponent}</div>
        <div className='col-1'>{newPlaylistButton}</div>
        <div className='col-2'>{setupComponent}</div>
      </div>
    );

    return (
      <div
        className='container-fluid main'
        style={{ backgroundColor: '#343B3B' }}
      >
        <br />
        <br />
        <br />
        {toolbar}
        {songCardsComponent}
        <br />
        <br />
        <br />
        {audioPlayerComponent}
      </div>
    );
  }
}

export default Main;
