import React, { Component } from 'react';
import BeatChain from '../abis/BeatChain.json';
import Navbar from './Navbar';
import Main from './Main';
import Web3 from 'web3';
import './App.css';
import 'react-h5-audio-player/lib/styles.css';
import axios from 'axios';
import ScaleLoader from 'react-spinners/ScaleLoader';

class App extends Component {
  async componentWillMount() {
    await this.loadWeb3();
    await this.loadBlockchainData();
  }

  async loadWeb3() {
    // connect to Web3 client
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        'Non-Ethereum browser detected. You should consider trying MetaMask!'
      );
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3;
    // load crypto account
    const accounts = await web3.eth.getAccounts();
    this.setState({ account: accounts[0] });
    // load chain's network ID
    const networkId = await web3.eth.net.getId();
    const networkData = BeatChain.networks[networkId];
    if (networkData) {
      const beatchain = new web3.eth.Contract(
        BeatChain.abi,
        networkData.address
      );
      this.setState({ beatchain });
      const videosCount = await beatchain.methods.videoCount().call();
      this.setState({ videosCount });
      // load videos, sort by newest
      this.setState({ videos: [] });
      for (var i = videosCount; i >= 1; i--) {
        const video = await beatchain.methods.videos(i).call();
        this.setState({
          videos: [...this.state.videos, video],
        });
      }
      //Set latest video with title to view as default
      const latest = await beatchain.methods.videos(videosCount).call();
      this.setState({
        // currentHash: latest.hash,
        currentTitle: 'Beatchain',
        currentArtist: 'Welcome',
        source:
          'https://bafybeigvlxh7m7vpdgqqin2bem2k2kgul2vsgdalgmhb7uvmq2hhreptgq.ipfs.infura-ipfs.io/',
      });
      this.setState({ loading: false });
      // Fetch all playlists from API
      const self = this;
      await axios({
        method: 'get',
        url: 'https://beatchain-backend.herokuapp.com/fetch-all-playlists',
      })
        .then(function (response) {
          self.setState({ playlists: response.data.playlists });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      window.alert('BeatChain contract not deployed to detected network.');
    }
  }

  async audioToBase64(file) {
    // convert audio file to base64 string
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = (e) => resolve(e.target.result);
      reader.readAsDataURL(file);
    });
  }

  captureFile = (event) => {
    // capture audio file
    event.preventDefault();
    const file = event.target.files[0];
    this.audioToBase64(file).then((result) => {
      this.setState({ buffer: result });
    });
  };

  captureArtwork = (event) => {
    // capture artwork
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      this.setState({ albumArt: Buffer(reader.result) });
    };
  };

  uploadVideo = (title, artistName, costPerStream) => {
    // send file to backend
    // wait for hash returned
    // use returned hash, title, etc to upload to chain (as per before)
    const self = this;
    if (this.state.albumArt == null) {
      this.setState({ albumArt: '../albumart.jpg' });
    }
    this.setState({ uploading: true });
    axios({
      method: 'post',
      url: 'https://beatchain-backend.herokuapp.com/upload',
      data: {
        buffer: this.state.buffer,
        title: title,
        artistName: artistName,
        costPerStream: costPerStream,
        albumArt: this.state.albumArt,
      },
    })
      .then(function (response) {
        console.log('Song successfully uploaded');
        // talk to chain
        // self.setState({ loading: true });
        self.state.beatchain.methods
          .uploadVideo(
            response.data.ipfsHash,
            title,
            costPerStream,
            response.data.artHash,
            artistName
          )
          .send({ from: self.state.account })
          .on('transactionHash', (hash) => {
            var temp = self.state.videos;
            var newVideo = {
              id: self.state.videos.length + 1,
              hash: response.data.ipfsHash,
              title: title,
              costPerStream: costPerStream,
              artHash: response.data.artHash,
              artist: artistName,
            };
            temp = [newVideo, ...temp];
            self.setState({ videos: temp });
            self.setState({ buffer: null, albumArt: null });
            self.setState({ uploading: false });
            self.setState({ uploadModalShow: false });
            console.log('Added to smart contract');
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  convertDataURIToBinary(dataURI) {
    // conversion of decrypted file to binary
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  changeVideo = (id, hash, title, costPerStream, artist) => {
    const web3 = window.web3;
    let songHash = hash;

    // user clicked on the same track
    if (id == this.state.currentId) {
      return;
    }

    // frontend makes payment
    this.state.beatchain.methods
      .stream(Number(id))
      .send({
        from: this.state.account,
        value: web3.utils.toWei(costPerStream.toString(), 'ether'),
      })
      .on('transactionHash', async (hash) => {
        // frontend tells the backend which hash
        console.log('Completed payment, making BE request now');
        return await axios({
          method: 'post',
          url: 'https://beatchain-backend.herokuapp.com/play',
          data: {
            songHash: songHash,
          },
        })
          .then((response) => {
            // backend decrypts file and sends it back
            var base64data = response.data.base64file;

            // convert base64 string to binary data
            var binary = this.convertDataURIToBinary(base64data);
            var blob = new Blob([binary], { type: 'audio/ogg' });
            var blobURL = URL.createObjectURL(blob);

            this.setState({ currentId: id });
            this.setState({ currentHash: hash });
            this.setState({ currentTitle: title });
            this.setState({ currentArtist: artist });
            this.setState({ source: blobURL });
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  handleSort = (field) => {
    // sorting function
    this.setState({
      sort: field,
    });
    console.log(field);
    var temp = this.state.videos;
    temp.sort((a, b) => {
      if (a[field] > b[field]) {
        return field === 'id' ? -1 : 1;
      }
      if (b[field] > a[field]) {
        return field === 'id' ? 1 : -1;
      }
      return 0;
    });
    this.setState({
      videos: temp,
    });
  };

  createPlaylist = (title) => {
    // create a playlist
    const self = this;
    axios({
      method: 'post',
      url: 'https://beatchain-backend.herokuapp.com/create-playlist',
      data: {
        title: title,
      },
    })
      .then(function (response) {
        var temp = self.state.playlists;
        var newPlaylist = {
          id: response.data.playlistId,
          title: title,
          songs: [],
        };
        temp = [...temp, newPlaylist];
        self.setState({ playlists: temp });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  selectPlaylist = (playlistId, playlistTitle) => {
    // select a playlist
    this.setState({
      playlist: { title: playlistTitle, id: playlistId },
    });
    console.log(this.state.playlist);
  };

  addToPlaylist = (playlistId, songId) => {
    // add song to playlist
    const self = this;
    axios({
      method: 'post',
      url: 'https://beatchain-backend.herokuapp.com/add-song-to-playlist',
      data: {
        playlistId: playlistId,
        songId: songId,
      },
    })
      .then(function (response) {
        var temp = self.state.playlists;
        temp
          .find((playlist) => playlist.id == self.state.playlist.id)
          .songs.push(songId);
        self.setState({ playlists: temp });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleUploadModal = () => {
    this.setState({
      uploadModalShow: !this.state.uploadModalShow,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      buffer: null,
      account: '',
      beatchain: null,
      videos: [],
      loading: true,
      currentHash: null,
      currentTitle: null,
      currentId: null,
      currentArtist: null,
      data: null,
      albumArt: null,
      sort: 'id',
      uploading: false,
      playlists: ['1', '2'],
      playlist: { title: 'All Songs', id: 'all' },
      uploadModalShow: false,
    };

    this.uploadVideo = this.uploadVideo.bind(this);
    this.captureFile = this.captureFile.bind(this);
    this.captureArtwork = this.captureArtwork.bind(this);
    this.changeVideo = this.changeVideo.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.selectPlaylist = this.selectPlaylist.bind(this);
    this.createPlaylist = this.createPlaylist.bind(this);
    this.addToPlaylist = this.addToPlaylist.bind(this);
    this.handleUploadModal = this.handleUploadModal.bind(this);
  }

  render() {
    return (
      <div style={{ backgroundColor: '#343B3B', minHeight: '100vh' }}>
        <Navbar account={this.state.account} />
        {this.state.loading ? (
          <div className='container h-100'>
            <div className='d-flex justify-content-md-center align-items-center vh-100'>
              <ScaleLoader
                color='#ffffff'
                loading='true'
                height={35}
                width={6}
                radius={2}
                margin={2}
              />
            </div>
          </div>
        ) : (
          <Main
            videos={this.state.videos}
            uploadVideo={this.uploadVideo}
            captureFile={this.captureFile}
            captureArtwork={this.captureArtwork}
            changeVideo={this.changeVideo}
            currentHash={this.state.currentHash}
            currentTitle={this.state.currentTitle}
            currentId={this.state.currentId}
            currentArtist={this.state.currentArtist}
            source={this.state.source}
            sorter={this.handleSort}
            sort={this.state.sort}
            uploading={this.state.uploading}
            playlists={this.state.playlists}
            playlist={this.state.playlist}
            selectPlaylist={this.selectPlaylist}
            createPlaylist={this.createPlaylist}
            addToPlaylist={this.addToPlaylist}
            uploadModalShow={this.state.uploadModalShow}
            handleUploadModal={this.handleUploadModal}
          />
        )}
      </div>
    );
  }
}

export default App;
